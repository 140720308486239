<template>
  <v-row class="app-preview">
    <v-col
      cols="12"
      md="9"
    >
      <v-card class="app-invoice-editable">
        <!-- Header -->
        <div class="py-5 px-5">
          <Letterhead v-if="userRole !== 'Associate'" :width="'100%'" />

          <div class="d-flex flex-wrap justify-space-between mt-9">
            <!-- Date -->
            <div class="d-flex align-center">
              <span class="me-2 font-weight-medium text--primary">Date Issued: {{ dateToday }}</span>
            </div>

            <!-- Invoice number -->
            <div
              class="d-flex align-center justify-end"
              :class="{ 'mt-4': $vuetify.breakpoint.xsOnly }"
            >
              <v-select
                v-model="invoice.branch_id"
                :items="branchOptions"
                item-value="id"
                item-text="name"
                single-line
                outlined
                dense
                hide-details
                clearable
                placeholder="Select Branch"
                :class="{ 'invoice-add-branch': $vuetify.breakpoint.smAndUp }"
              ></v-select>
            </div>
          </div>
        </div>

        <v-divider></v-divider>

        <!-- Customer Details -->
        <div class="py-5 px-5">
          <div class="mb-3 d-flex">
            <div class="px-3 flex-grow-1 font-weight-semibold">
              <v-row>
                <!-- Customer Name -->
                <v-col
                  cols="12"
                  sm="6"
                  :class="{ 'pl-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                >
                  <p class="font-weight-semibold payment-details-header">
                    Invoice To:
                  </p>
                  <v-text-field
                    v-model="invoice.customer_name"
                    single-line
                    dense
                    outlined
                    hide-details
                    placeholder="Customer Name"
                  ></v-text-field>
                </v-col>

                <!-- Customer Contact -->
                <v-col
                  cols="12"
                  sm="6"
                  :class="{ 'pr-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                >
                  <p class="font-weight-semibold payment-details-header">
                    Contact No.:
                  </p>
                  <v-text-field
                    v-model="invoice.customer_contact_number"
                    single-line
                    dense
                    outlined
                    type="number"
                    :rules="[v => /[0-9\-().\s]{7}$/.test(v) || 'Must be valid contact number']"
                    hide-details="auto"
                    placeholder="Customer Contact Number"
                    prefix="+673"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>

        <v-divider></v-divider>

        <!-- Invoice Details -->
        <div class="py-5 px-5">
          <div class="mb-3 d-none d-md-flex">
            <div class="px-3 flex-grow-1 font-weight-semibold">
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <span>Description</span>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <span>Amount</span>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <span>Summable Amount</span>
                </v-col>
              </v-row>
            </div>
            <div class="header-spacer"></div>
          </div>

          <!-- Insurance Category -->
          <v-card
            outlined
            class="d-flex pa-3"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="invoice.insurance_category_id"
                  :items="insuranceCategoryOptions"
                  item-value="id"
                  item-text="name"
                  dense
                  outlined
                  hide-details="auto"
                  placeholder="Select Insurance Category"
                ></v-select>
              </v-col>
            </v-row>
          </v-card>

          <!-- Insurance Product Type -->
          <v-card
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="invoice.insurance_product_id"
                  :items="insuranceProductOptions"
                  item-value="id"
                  item-text="name"
                  dense
                  outlined
                  hide-details="auto"
                  placeholder="Select Insurance Product Type"
                ></v-select>
              </v-col>
            </v-row>
          </v-card>

          <!-- Car Registration Number -->
          <v-card
            v-if="isMotorInsurance"
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="invoice.car_registration_number"
                  dense
                  outlined
                  hide-details="auto"
                  placeholder="Car Registration Number"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>

          <!-- Insurance -->
          <v-card
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="invoice.insurance_id"
                  :items="insuranceOptions"
                  item-value="id"
                  item-text="name"
                  dense
                  outlined
                  hide-details="auto"
                  placeholder="Select Insurance"
                ></v-select>
              </v-col>
            </v-row>
          </v-card>

          <!-- Sum Insured -->
          <v-card
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
                class="d-flex align-center"
              >
                <p class="mb-0">
                  Sum Insured
                </p>
              </v-col>
              <v-col
                cols="12"
                sm="3"
              >
                <v-text-field
                  v-model="invoice.sum_insured"
                  outlined
                  dense
                  min="0"
                  type="number"
                  hide-details="auto"
                  prefix="$"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>

          <!-- Insurance Premium -->
          <v-card
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
                class="d-flex align-center"
              >
                <p class="mb-0">
                  Insurance Premium
                </p>
              </v-col>
              <v-col
                cols="12"
                sm="3"
              >
                <v-text-field
                  v-model="invoice.insurance_premium"
                  outlined
                  dense
                  min="0"
                  type="number"
                  hide-details="auto"
                  prefix="$"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>

          <!-- After Discount -->
          <v-card
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <p class="mb-0">
                  After Discount
                </p>
              </v-col>
              <v-col
                cols="12"
                offset-sm="3"
                sm="3"
              >
                <v-text-field
                  v-model="invoice.after_discount"
                  outlined
                  dense
                  min="0"
                  type="number"
                  hide-details="auto"
                  prefix="$"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>

          <!-- Road Tax Price -->
          <v-card
            v-if="isMotorInsurance"
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
                class="d-flex align-center"
              >
                <p class="mb-0">
                  Road Tax Price
                </p>
              </v-col>
              <v-col
                cols="12"
                offset-sm="3"
                sm="3"
              >
                <v-text-field
                  v-model="invoice.road_tax"
                  outlined
                  dense
                  min="0"
                  type="number"
                  hide-details="auto"
                  prefix="$"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>

          <!-- Pickup Location -->
          <v-card
            v-if="isMotorInsurance"
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="invoice.pickup_location_id"
                  :items="pickupLocationOptions"
                  item-value="id"
                  item-text="name"
                  dense
                  outlined
                  hide-details="auto"
                  placeholder="Select Road Tax Pickup Location"
                ></v-select>

                <template>
                  <v-text-field
                    v-if="isOtherPickupLocation"
                    v-model="invoice.remarks.pickup_location"
                    single-line
                    dense
                    outlined
                    hide-details="auto"
                    placeholder="Enter Road Tax Pickup Location"
                    class="mt-3"
                  ></v-text-field>
                </template>
              </v-col>
            </v-row>
          </v-card>

          <!-- Additional Fees -->
          <v-card
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
                class="d-flex align-center"
              >
                <p class="mb-0">
                  Additional Fees
                </p>
              </v-col>
              <v-col
                cols="12"
                offset-sm="3"
                sm="3"
              >
                <v-text-field
                  v-model="invoice.additional_fees"
                  outlined
                  dense
                  min="0"
                  type="number"
                  hide-details="auto"
                  prefix="$"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>

          <!-- General remarks -->
          <v-card
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="invoice.remarks.general"
                  dense
                  outlined
                  hide-details="auto"
                  placeholder="General Remarks"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>

          <!-- SPD Reference Number -->
          <v-card
            v-if="(invoice.insurance_category_id == 1 || invoice.insurance_category_id == 2) && invoice.insurance_product_id == 1"
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="invoice.spd_number"
                  dense
                  outlined
                  maxlength="4"
                  hide-details="auto"
                  placeholder="SPD Reference Number"
                  hint="Enter SPD Reference No. last 4 digits"
                  persistent-hint
                  @keypress="isNumber"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>

          <!-- Due date -->
          <v-card
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
                class="d-flex align-center"
              >
                <p class="mb-0">
                  Due Date
                </p>
              </v-col>
              <v-col
                cols="12"
                offset-sm="3"
                sm="3"
              >
                <v-menu
                  v-model="isDateMenuOpen"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :append-icon="icons.mdiCalendar"
                      :value="new Date(invoice.due_date).toLocaleDateString()"
                      readonly
                      dense
                      outlined
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="invoice.due_date"
                    :min="new Date().toISOString().substring(0, 10)"
                    color="primary"
                    :first-day-of-week="1"
                    @input="isDateMenuOpen = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-card>
        </div>

        <v-divider></v-divider>

        <!-- Invoice Footer -->
        <div class="py-5 px-5">
          <!-- Issuer -->
          <v-row>
            <v-col
              cols="12"
              sm="6"
              class="d-flex align-center"
            >
              <p class="mb-0 pr-2 font-weight-semibold">
                Issued By:
              </p>
              <p class="mb-0">
                {{ userData.nickname }}
              </p>
            </v-col>

            <v-col
              cols="4"
              sm="3"
              class="d-flex align-center justify-end"
            >
              <p class="mb-0 font-weight-semibold">
                Total:
              </p>
            </v-col>

            <!-- Total -->
            <v-col
              cols="8"
              sm="3"
              :class="{ 'px-0': $vuetify.breakpoint.xsOnly }"
            >
              <p class="mb-0 text-xl font-weight-semibold">
                ${{ calculatedTotal }}
              </p>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-col>

    <v-col
      cols="12"
      md="3"
    >
      <v-card class="mb-6">
        <v-card-text>
          <v-btn
            color="primary"
            block
            :loading="loading"
            :disabled="loading"
            @click="isCommentDialogVisible = true"
          >
            {{ t('Send Request') }}
          </v-btn>

          <div class="text-center mt-4">
            <Alert
              :messages="[t('All invoices need to be settled before Sales Report Collection.')]"
              color="warning"
            >
            </Alert>
          </div>

          <div class="text-center mt-4">
            {{ t('Please ensure that all fields are filled correctly as this record will not be editable after confirming.') }}
          </div>
        </v-card-text>
      </v-card>
      <v-card
        v-if="errors.length != 0"
        class="mb-6"
      >
        <v-card-text>
          <Alert
            :messages="errors"
            color="error"
          ></Alert>
        </v-card-text>
      </v-card>
    </v-col>

    <!-- Comment dialog -->
    <v-dialog
      v-model="isCommentDialogVisible"
      width="500"
    >
      <v-card>
        <v-card-title class="d-flex align-center mv-4">
          Request to issue invoice?
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isCommentDialogVisible = false"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-textarea
            v-model="newComment"
            dense
            outlined
            hide-details="auto"
            placeholder="Leave your comment here (optional)"
            :disabled="loading"
            class="mb-4"
          ></v-textarea>
          <p>
            This action will create an create request & the invoice will be saved after approved by auditor.
          </p>
          <p class="mb-0">
            Please leave any remarks that the auditor should know about the changes.
          </p>
        </v-card-text>

        <v-card-actions class="mt-2">
          <v-btn
            color="primary"
            block
            :loading="loading"
            :disabled="loading"
            @click="saveRequest()"
          >
            Yes, send request
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mdiCalendar } from '@mdi/js'
import {
  inject,
  onUnmounted,
  onMounted,
  computed,
  ref,
} from '@vue/composition-api'
import { useRouter } from '@core/utils'
import { Letterhead } from '@/components/apps/previews'
import Alert from '@/components/Alert'
import invoiceStoreModule from './invoiceStoreModule'

export default {
  components: {
    Letterhead,
    Alert,
  },
  setup() {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const t = inject('t')
    const { router } = useRouter()
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) {
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)
    }

    // Properties
    const invoice = ref({
      customer_name: null,
      customer_contact_number: null,
      insurance_category_id: null,
      insurance_product_id: null,
      car_registration_number: null,
      insurance_id: null,
      pickup_location_id: null,
      sum_insured: null,
      insurance_premium: null,
      after_discount: null,
      road_tax: null,
      additional_fees: null,
      spd_number: null,
      remarks: {
        general: null,
        pickup_location: null,
      },
      due_date: null,
    })
    const errors = ref([])
    const userData = JSON.parse(localStorage.getItem('userData'))
    const userRole = userData && userData.role ? userData.role : null
    const dateToday = new Date().toLocaleDateString('en-SG')
    const insuranceCategoryOptions = ref([])
    const insuranceProductOptions = ref([])
    const insuranceOptions = ref([])
    const pickupLocationOptions = ref([])
    const motorInsuranceId = ref(null)
    const otherPickupLocationId = ref(null)
    const loading = ref(false)
    const isDateMenuOpen = ref(false)
    const isCommentDialogVisible = ref(false)
    const newComment = ref(null)

    // Methods
    const fetchInsuranceCategories = () => {
      store
        .dispatch('app-invoice/fetchInsuranceCategories')
        .then(response => {
          const { data } = response.data
          insuranceCategoryOptions.value = data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching insurance categories. Please refresh!')
        })
    }
    const fetchInsuranceProducts = () => {
      store
        .dispatch('app-invoice/fetchInsuranceProducts')
        .then(response => {
          const { data } = response.data
          insuranceProductOptions.value = data
          motorInsuranceId.value = insuranceProductOptions.value.filter(product => product.name === 'Motor insurance')[0].id
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching insurance products. Please refresh!')
        })
    }
    const fetchInsurances = () => {
      store
        .dispatch('app-invoice/fetchInsurances')
        .then(response => {
          const { data } = response.data
          insuranceOptions.value = data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching insurances. Please refresh!')
        })
    }
    const fetchPickupLocations = () => {
      store
        .dispatch('app-invoice/fetchPickupLocations')
        .then(response => {
          const { data } = response.data
          pickupLocationOptions.value = data
          otherPickupLocationId.value = pickupLocationOptions.value.filter(pickup => pickup.name === 'Others')[0].id
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching pickup location. Please refresh!')
        })
    }
    const reassignEmptyString = () => {
      const attributes = ['additional_fees', 'road_tax']
      attributes.forEach(attr => {
        if (invoice.value[attr] === null) invoice.value[attr] = ''
      })
    }
    const createComment = id => {
      store
        .dispatch('requestStore/createComment', {
          user_id: userData.id,
          approval_request_id: id,
          comment: newComment.value,
        })
        .then(response => {
          snackbarService.success(response.data.message)
          router.push('/apps/requests/list')
        })
        .catch(error => {
          loading.value = false
          errors.value = error.response.data.errors
          snackbarService.error(error.response.data.message || 'Something went wrong while saving comment. Please refresh!')
        })
    }
    const saveRequest = () => {
      loading.value = true
      errors.value = []
      store
        .dispatch('app-invoice/createInvoice', invoice.value)
        .then(response => {
          const { data } = response.data
          invoice.value.id = data.id
          delete invoice.value.issuer
          reassignEmptyString()
          const requestParam = {
            record_id: invoice.value.id,
            record_type: 'Invoice',
            requested_by_id: userData.id,
            action: 'Create',
            params: invoice.value,
            rejected_at: null,
            responded_by: null,
            comment: newComment.value,
          }
          store
            .dispatch('requestStore/createRequest', requestParam)
            .then(res => {
              if (newComment.value) {
                createComment(res.data.data.id)
              } else {
                snackbarService.success(res.data.message)
                router.push('/apps/requests/list')
              }
            })
            .catch(error => {
              loading.value = false
              isCommentDialogVisible.value = false
              errors.value = error.response.data.errors
              snackbarService.error(error.response.data.message || 'Something went wrong while saving request. Please refresh!')
            })
        })
        .catch(error => {
          loading.value = false
          isCommentDialogVisible.value = false
          errors.value = error.response.data.errors
          snackbarService.error(error.response.data.message || 'Something went wrong while saving invoice. Please refresh!')
        })
    }
    const isNumber = event => {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
      const keyPressed = event.key
      if (!keysAllowed.includes(keyPressed)) {
        event.preventDefault()
      }
    }
    const initialiseDueDate = () => {
      const today = new Date()
      invoice.value.due_date = new Date(today.setDate(today.getDate() + 21)).toISOString().substring(0, 10)
    }

    // Computed
    const branchOptions = computed(() => store.state.branches.filter(b => b.active))
    const isMotorInsurance = computed(() => (motorInsuranceId.value === invoice.value.insurance_product_id))
    const isOtherPickupLocation = computed(() => {
      if (otherPickupLocationId.value !== invoice.value.pickup_location_id) invoice.value.remarks.pickup_location = null

      return otherPickupLocationId.value === invoice.value.pickup_location_id
    })
    const calculatedTotal = computed(() => {
      const afterDiscount = parseFloat(invoice.value.after_discount) || 0.00
      const roadTax = parseFloat(invoice.value.road_tax) || 0.00
      const additionalFees = parseFloat(invoice.value.additional_fees) || 0.00

      return parseFloat(afterDiscount + roadTax + additionalFees).toFixed(2)
    })

    // Mounted
    onMounted(() => {
      fetchInsuranceCategories()
      fetchInsuranceProducts()
      fetchInsurances()
      fetchPickupLocations()
      initialiseDueDate()
    })

    // Unmounted
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    return {
      t,
      invoice,
      errors,
      saveRequest,
      userData,
      userRole,
      dateToday,
      branchOptions,
      insuranceCategoryOptions,
      insuranceProductOptions,
      insuranceOptions,
      pickupLocationOptions,
      isMotorInsurance,
      isOtherPickupLocation,
      calculatedTotal,
      loading,
      isNumber,
      initialiseDueDate,
      isDateMenuOpen,
      isCommentDialogVisible,
      newComment,

      icons: {
        mdiCalendar,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/style.scss';

.app-invoice-editable {
  .invoice-add-branch {
    width: 15rem;
  }
}
</style>
